import React from "react";
import App from "./App";
import { Link, navigate } from "gatsby";

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    navigate("/app/login");
  }
  render() {
    return <></>;
  }
}

export default IndexPage;
